import React, { useEffect, useState } from 'react';

import {
  Box,
  LinearProgress,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import {
  drawFacings,
  drawLabelReadings,
  drawLabels,
} from '../tools/Canvas/utils';

const Canvas = props => {
  const factor = props.factor ? props.factor : 1;
  const defaultZoom = props.defaultZoom ? props.defaultZoom : 50;

  const [zoomValuevh, setZoomValuevh] = useState(defaultZoom);
  const [open, setOpen] = useState(false);
  const [DetectionId, setDetectionId] = useState(null);
  const [diffX, setDiffX] = useState(0);
  const [isFailureLoadingImg, setIsFailureLoadingImg] = useState(false);

  const theme = useTheme();

  const IMAGECANVAS = props.imageCanvas
    ? props.imageCanvas
    : props.onModal
      ? 'modalImageCanvas'
      : 'imageCanvas';
  const CANVASCONTAINER = props.canvasContainer
    ? props.canvasContainer
    : props.onModal
      ? 'modalCanvasContainer'
      : 'canvasContainer';

  useEffect(() => {
    const canvasContainer = document.getElementById(CANVASCONTAINER);
    const canvas = document.getElementById(IMAGECANVAS);
    canvasContainer.style.cursor = props.onModal ? 'arrow' : 'all-scroll';
    canvas.style.cursor = props.onModal ? 'arrow' : 'grab';
    if (!props.onModal && props.image_url) {
      canvasContainer.addEventListener('wheel', handleWheel);
      canvasContainer.addEventListener('mousedown', handleMouseDown);
      canvasContainer.addEventListener('mouseup', handleMouseUp);
      canvasContainer.addEventListener('mousemove', handleMouseMove);
      canvasContainer.addEventListener('contextmenu', handlecontextmenu);
      canvasContainer.addEventListener('pointermove', handlePointerMove);
    }

    return () => {
      if (!props.onModal) {
        canvasContainer.removeEventListener('wheel', handleWheel);
        canvasContainer.removeEventListener('mousedown', handleMouseDown);
        canvasContainer.removeEventListener('mouseup', handleMouseUp);
        canvasContainer.removeEventListener('mousemove', handleMouseMove);
        canvasContainer.removeEventListener('contextmenu', handlecontextmenu);
        canvasContainer.removeEventListener('pointermove', handlePointerMove);
      }
    };
  }, [
    zoomValuevh,
    props.isLoadingImages,
    props.isLoadingSecondaryImages,
    defaultZoom,
    props.onModal,
    props.image_url,
  ]);

  useEffect(() => {
    setDiffX(0);
    setZoomValuevh(defaultZoom);
  }, [props.defaultZoom]);

  useEffect(() => {
    if (!props.onModal && !props.image_url) {
      setIsFailureLoadingImg(false);
      setDiffX(0);
      setZoomValuevh(defaultZoom);
      clean();
      cleanModal();
    }
  }, [props.isLoadingImages, props.metadata, props.isLoadingImageUrl]);

  useEffect(() => {
    if (props.image_url && props.onModal) {
      setDiffX(0);
      cleanModal();
    }
  }, [props.isLoadingSecondaryImages]);

  useEffect(() => {
    setDiffX(0);
    if (props.image_url) {
      drawImage();
    }
  }, [
    props.metadata,
    props.formatCurrency,
    props.canShowSecondStage,
    props.canShowPositionPrediction,
    props.defaultZoom,
    props.splitView,
    props.stage,
  ]);

  useEffect(() => {
    if (props.image_url) {
      drawImage();
    }
  }, [props.opacity]);

  const clean = () => {
    const canvas = document.getElementById(IMAGECANVAS);
    const ctx = canvas.getContext('2d');
    ctx.canvas.width = ctx.canvas.width; // ? Yes, this is correct. The canvas clears when there is a change on its dimensions even if the dimensions are the same.
    //TODO: In the future we should change it to ctx.reset() when it's supported by all browsers (https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/reset)
  };

  const cleanModal = () => {
    if (props.onModal) {
      const canvas = document.getElementById(IMAGECANVAS);
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const drawImage = () => {
    const canvas = document.getElementById(IMAGECANVAS);
    const container = document.getElementById(CANVASCONTAINER);
    const ctx = canvas.getContext('2d');
    const img = new Image();

    if (props.image_url) {
      img.src = props.image_url.image_url;
    }

    // handle image loading error
    img.onerror = function () {
      setIsFailureLoadingImg(true);
    };

    img.onload = function () {
      canvas.width = img.naturalWidth * factor;
      canvas.height = img.naturalHeight * factor;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      if (props.ai !== 'Shelfout Detection' && canvas.scrollWidth > container.offsetWidth) {
        setDiffX(
          defaultZoom -
          (container.offsetWidth / canvas.scrollWidth) * defaultZoom,
        );
      }

      if (props.readingCanvas) {
        ctx.fillStyle = `rgba(0,0,0,${props.opacity})`;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      if (!props.isLoadingImages && !props.isLoadingSecondaryImages && !props.isLoadingImageUrl) {
        if (props.metadata && props.metadata[0] && props.metadata[0].x1 !== undefined) {
          drawLabels(
            props.metadata,
            IMAGECANVAS,
            theme,
            factor,
            props.selectedLabel,
          );
        } else if (props.metadata && props.metadata[0]) {
          drawFacings(
            props.metadata,
            IMAGECANVAS,
            theme,
            props.canShowPositionPrediction,
            factor,
          );
        } else if (props.metadata && props.metadata[0]) {
          drawLabelReadings(
            props.metadata,
            props.currencyFormat,
            props.formatCurrency,
            IMAGECANVAS,
            theme,
            props.onModal ? true : false,
            false,
            factor,
            props.readingCanvas,
            props.chain,
            props.splitView,
            props.stage,
          );
        }
      }
    };
  };

  const handleWheel = event => {
    event.preventDefault();
    const container = document.getElementById(CANVASCONTAINER);
    const { scrollLeft: oldScrollLeft, scrollTop: oldscrollTop } = container;
    const { offsetX, offsetY, deltaY } = event;

    const minZoom = defaultZoom;
    const maxZoom = 2000;
    let newZoom = zoomValuevh * (deltaY * -0.001 + 1);
    newZoom = Math.max(minZoom, Math.min(maxZoom, newZoom));

    const ratio = newZoom / zoomValuevh;
    setZoomValuevh(newZoom);

    container.scrollLeft = oldScrollLeft + offsetX * (ratio - 1);
    container.scrollTop = oldscrollTop + offsetY * (ratio - 1);
  };

  const handleMouseDown = event => {
    const container = document.getElementById(CANVASCONTAINER);
    container.style.cursor = 'grabbing';
    const canvas = document.getElementById(IMAGECANVAS);
    canvas.style.cursor = 'grabbing';
    container.dataset.scrollLeft = container.scrollLeft;
    container.dataset.scrollTop = container.scrollTop;
    container.dataset.clientX = event.clientX;
    container.dataset.clientY = event.clientY;
  };

  const handleMouseUp = event => {
    const container = document.getElementById(CANVASCONTAINER);
    const canvas = document.getElementById(IMAGECANVAS);
    container.style.cursor = 'all-scroll';
    canvas.style.cursor = 'grab';
  };

  const handleMouseMove = event => {
    const container = document.getElementById(CANVASCONTAINER);
    if (container.style.cursor === 'grabbing') {
      container.scrollLeft =
        container.dataset.scrollLeft -
        (event.clientX - container.dataset.clientX);
      container.scrollTop =
        container.dataset.scrollTop -
        (event.clientY - container.dataset.clientY);
    }
  };

  const handlecontextmenu = event => {
    event.preventDefault();
    const canvas = document.getElementById(IMAGECANVAS);
    const x = event.offsetX;
    const y = event.offsetY;
    const xImage = x * (canvas.width / canvas.clientWidth);
    const yImage = y * (canvas.height / canvas.clientHeight);
    if (
      (props.metadata &&
        props.metadata[0] &&
        props.metadata[0].x1 !== undefined) ||
      [
        'Label Reading',
        'Shelfout Detection',
        'Overhead Inventory Box Reading',
        'Promotional Signage Reading',
      ].includes(props.ai)
    ) {
      props.metadata.forEach(label => {
        if (
          (xImage > label.x1 * factor &&
            xImage < label.x2 * factor &&
            yImage > label.y1 * factor &&
            yImage < label.y2 * factor) ||
          [
            'Label Reading',
            'Overhead Inventory Box Reading',
            'Promotional Signage Reading',
          ].includes(props.ai) ||
          (label.prediction_bbox &&
            xImage > label.prediction_bbox[0] * factor &&
            xImage < label.prediction_bbox[2] * factor &&
            yImage > label.prediction_bbox[1] * factor &&
            yImage < label.prediction_bbox[3] * factor &&
            props.ai === 'Shelfout Detection')
        ) {
          const selectedLabel = [
            'Label Reading',
            'Promotional Signage Reading',
          ].includes(props.ai)
            ? props.id
            : props.ai === 'Product Detection'
              ? label.product_detection_id
              : label.detection_id;
          props.setSelectedLabel(selectedLabel);
          setOpen(false);
          // TODO: why this is inside a forEach? It should be outside, research and fix
          if (
            [
              'Label Reading',
              'Overhead Inventory Box Reading',
              'Promotional Signage Reading',
            ].includes(props.ai)
          ) {
            const capture_id = selectedLabel.split('_')[0];
            props.handleOpen(capture_id, 'capture', props.metadata);
          } else if (props.ai === 'Product Detection') {
            props.handleOpen(
              {
                product_detection_id: label.product_detection_id,
                capture_id: label.capture_id,
              },
              'product_detection_id',
              props.metadata,
            );
          } else {
            props.handleOpen(selectedLabel, 'detection', props.metadata);
          }
        }
      });
    }
  };

  const handlePointerMove = event => {
    const canvas = document.getElementById(IMAGECANVAS);
    const x = event.offsetX;
    const y = event.offsetY;
    const xImage = x * (canvas.width / canvas.clientWidth);
    const yImage = y * (canvas.height / canvas.clientHeight);
    if (canvas.style.cursor !== 'grabbing') {
      canvas.style.cursor = 'grab';
      setOpen(false);
      if (
        (props.metadata &&
          props.metadata[0] &&
          props.metadata[0].x1 !== undefined) ||
        [
          'Label Reading',
          'Shelfout Detection',
          'Overhead Inventory Box Reading',
          'Promotional Signage Reading',
        ].includes(props.ai)
      ) {
        props.metadata.forEach(label => {
          if (
            (xImage > label.x1 * factor &&
              xImage < label.x2 * factor &&
              yImage > label.y1 * factor &&
              yImage < label.y2 * factor) ||
            [
              'Label Reading',
              'Overhead Inventory Box Reading',
              'Promotional Signage Reading',
            ].includes(props.ai) ||
            (label.prediction_bbox &&
              xImage > label.prediction_bbox[0] * factor &&
              xImage < label.prediction_bbox[2] * factor &&
              yImage > label.prediction_bbox[1] * factor &&
              yImage < label.prediction_bbox[3] * factor &&
              props.ai === 'Shelfout Detection')
          ) {
            setOpen(
              props.ai !== 'Label Reading' &&
                props.ai !== 'Overhead Inventory Box Reading' &&
                props.ai !== 'Promotional Signage Reading'
                ? true
                : false,
            );
            setDetectionId(
              props.ai === 'Product Detection'
                ? label.detection_id
                  ? label.product_detection_id
                  : `${label.product_detection_id} ⚠️`
                : props.ai !== 'Label Reading' &&
                  props.ai !== 'Overhead Inventory Box Reading' &&
                  props.ai !== 'Promotional Signage Reading'
                  ? label.detection_id
                  : null,
            );
            canvas.style.cursor = 'context-menu';
          }
        });
      }
    }
  };

  return (
    <>
      {isFailureLoadingImg ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'grey',
            fontSize: '1rem',
            border: '1px solid grey',
            minHeight: '20em',
            backgroundColor: '#80808012',
          }}>
          <Typography variant="small" color="white">
            Image could not be loaded
          </Typography>
        </Box>
      ) : (
        <Box
          id={CANVASCONTAINER}
          className={CANVASCONTAINER}
          sx={{
            height: props.onModal ? 'auto' : `${defaultZoom}vh`,
            width: 'auto',
            overflow: 'hidden',
            border: '1px solid grey',
            ...props.sx,
          }}>
          <Tooltip title={DetectionId ?? '-'} followCursor open={open}>
            <canvas
              id={IMAGECANVAS}
              className={IMAGECANVAS}
              style={{
                width: props.onModal ? '100%' : 'auto',
                height: props.onModal ? 'auto' : `${zoomValuevh - diffX}vh`,
                marginBottom: '-7px',
              }}></canvas>
          </Tooltip>
        </Box>
      )}
      {(props.isLoadingImages || props.isLoadingImageUrl) && (
        <LinearProgress
          sx={{
            width: '99.5%',
            backgroundColor: theme.palette.background.default,
          }}
        />
      )}
    </>
  );
};
export default Canvas;
