import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Grid,
  LinearProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import Canvas from '../../redux/containers/visualizer/Canvas';
import StyledPaper from '../tools/StyledPaper';

const StyledTextField = styled(props => {
  return <TextField {...props} />;
})(({ theme }) => {
  return {
    '& .MuiInputLabel-root': {
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '& .MuiFilledInput-root': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '& .MuiOutlinedInput-root': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color:
        theme.palette.type === 'light' ? theme.palette.text.primary : 'white',
    },
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'light'
          ? 'rgba(255, 255, 255, 0.17)'
          : theme.palette.text.primary,
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.17)',
    },
  };
});

const DetectionModal = props => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(props.open);
  const theme = useTheme();
  const [labelMetadata, setlabelMetadata] = useState(null);
  const [id, setId] = useState(null);
  const [confidence, setConfidence] = useState(null);
  const loadingConditionImage = props.images && props.images.image && !props.isLoadingImages && props.images.image.length > 0;

  useEffect(() => {
    setIsOpen(props.open);
    setlabelMetadata(null);
    setId(null);
    setConfidence(null);
    if (props.images && props.images.image && props.images.image[0]) {
      props.signSecondaryImage({ store: props.store, blob_path: props.images.image[0].image_url });
    }
    if (props.images && props.images.image && props.images.image[0] && props.ai !== 'Shelfout Detection') {
      if (props.ai === 'Label Detection' || props.ai === 'Product Detection' || props.ai === 'Overhead Inventory Box Detection') {
        setlabelMetadata(props.images.metadata[props.selectedLabel]);
      } else {
        setlabelMetadata(props.preMetadata);
      }
    }
    if (props.images && props.images.image && props.images.image[0]) {
      if (['Label Reading', 'Overhead Inventory Box Reading', 'Promotional Signage Reading',].includes(props.ai)) {
        setId(props.selectedLabel.split('_')[0]);
        setConfidence(props.images.metadata[props.selectedLabel.split('_')[0]].find(e => e.detection_id === props.selectedLabel).score);
      } else if (props.ai === 'Product Detection') {
        setId(props.selectedLabel);
        setConfidence(props.preMetadata.find(e => e.product_detection_id === props.selectedLabel).capture_detection_score);
      } else {
        setId(props.selectedLabel);
        setConfidence(props.preMetadata.find(e => e.detection_id === props.selectedLabel).score);
      }
    }
  }, [props.open, props.images]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: !loadingConditionImage ? '1rem' : 'auto',
    bgcolor: theme.palette.paper.main,
    maxHeight: '90%',
    p: 3,
    overflow: 'hidden',
    overflowY: !loadingConditionImage ? 'hidden' : 'scroll',
    borderRadius: '5px',
    outline: 'none',
  };

  return (
    <Modal open={isOpen} onClose={props.onClose}>
      <Box sx={style}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          {props.ai !== 'Shelfout Detection' && (
            <Grid
              item
              xs={12}
              md={4}
              justifyContent={'center'}
              display={'flex'}>
              {!props.isLoadingImages ? (
                loadingConditionImage ? (
                  <Canvas
                    metadata={props.images ? props.images.metadata[id] : []}
                    onModal={true}
                    sx={{
                      width: '20em',
                      maxHeight: '38em',
                      overflow: 'auto',
                      minHeight: '20em',
                    }}
                    currencyFormat={props.currencyFormat}
                    formatCurrency={props.formatCurrency}
                    image_url={props.image_url}
                    selectedLabel={
                      [
                        'Label Reading',
                        'Overhead Inventory Box Reading',
                        'Promotional Signage Reading',
                      ].includes(props.ai)
                        ? props.selectedLabel
                        : null
                    }
                  />
                ) : (
                  <Typography
                    variant="h6"
                    component="p"
                    textAlign={'center'}
                    marginTop={'-1rem'}
                    fontFamily={theme.typography.fontFamily}
                    color={
                      theme.palette.type === 'light'
                        ? theme.palette.text.primary
                        : 'white'
                    }>
                    {'No image found'}
                  </Typography>
                )
              ) : null}
            </Grid>
          )}
          <Grid item xs={12} md={props.ai !== 'Shelfout Detection' ? 8 : 12}>
            {loadingConditionImage ? (
              <TableContainer component={StyledPaper}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="body2"
                          component="p"
                          fontFamily={theme.typography.fontFamily}
                          sx={{ fontWeight: 'bold' }}
                          color={
                            theme.palette.type === 'light'
                              ? theme.palette.text.primary
                              : 'white'
                          }>
                          {t('hip_app.general.type')}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body2"
                          component="p"
                          fontFamily={theme.typography.fontFamily}
                          sx={{ fontWeight: 'bold' }}
                          color={
                            theme.palette.type === 'light'
                              ? theme.palette.text.primary
                              : 'white'
                          }>
                          {t('hip_app.general.value')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          variant="body2"
                          component="p"
                          fontFamily={theme.typography.fontFamily}
                          sx={{ fontWeight: 'bold' }}
                          color={
                            theme.palette.type === 'light'
                              ? theme.palette.text.primary
                              : 'white'
                          }>
                          {t('hip_app.general.confidence')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingConditionImage && labelMetadata
                      ? labelMetadata.map((item, index) => {
                        switch (item.prediction_type) {
                          case 0:
                            return (
                              <TableRow key={item.value}>
                                <TableCell scope="row">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {t('hip_app.general.price')} (
                                    {item.prediction_type})
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {item.value}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {parseFloat(
                                      item.confidence * 100,
                                    ).toFixed(2) + '%'}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          case 3:
                            return (
                              <TableRow key={item.value}>
                                <TableCell scope="row">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {t('hip_app.general.text')} (
                                    {item.prediction_type})
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {item.value}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {parseFloat(
                                      item.confidence * 100,
                                    ).toFixed(2) + '%'}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          case 8:
                            return (
                              <TableRow key={item.value}>
                                <TableCell scope="row">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {t('hip_app.general.text')} (
                                    {item.prediction_type})
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {item.value}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {parseFloat(
                                      item.confidence * 100,
                                    ).toFixed(2) + '%'}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );

                          default:
                            return (
                              <TableRow key={item.value}>
                                <TableCell scope="row">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {item.prediction_type
                                      ? t('hip_app.general.item') +
                                      ' (' +
                                      item.prediction_type +
                                      ')'
                                      : t('hip_app.general.item')}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {item.value ?? item.item}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    fontFamily={theme.typography.fontFamily}
                                    color={
                                      theme.palette.type === 'light'
                                        ? theme.palette.text.primary
                                        : 'white'
                                    }>
                                    {item.confidence
                                      ? parseFloat(
                                        item.confidence * 100,
                                      ).toFixed(2) + '%'
                                      : item.score
                                        ? parseFloat(item.score * 100).toFixed(
                                          2,
                                        ) + '%'
                                        : NaN}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                        }
                      })
                      : props.isLoadingImages
                        ? null
                        : null}
                    <TableCell scope="row">
                      <Typography
                        variant="body2"
                        component="p"
                        fontFamily={theme.typography.fontFamily}
                        color={
                          theme.palette.type === 'light'
                            ? theme.palette.text.primary
                            : 'white'
                        }>
                        {props.ai === 'Shelfout Detection'
                          ? 'Product Detection ID'
                          : 'Detection ID'}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="body2"
                        component="p"
                        fontFamily={theme.typography.fontFamily}
                        color={
                          theme.palette.type === 'light'
                            ? theme.palette.text.primary
                            : 'white'
                        }>
                        {props.selectedLabel}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        component="p"
                        fontFamily={theme.typography.fontFamily}
                        color={
                          theme.palette.type === 'light'
                            ? theme.palette.text.primary
                            : 'white'
                        }>
                        {props.ai === 'Shelfout Detection'
                          ? loadingConditionImage &&
                          parseFloat(
                            props.images.metadata[props.aisle].find(
                              detection =>
                                detection.detection_id ===
                                props.selectedLabel,
                            ).prediction_score * 100,
                          ).toFixed(2) + '%'
                          : parseFloat(confidence * 100).toFixed(2) + '%'}
                      </Typography>
                    </TableCell>
                    {props.ai === 'Shelfout Detection' &&
                      loadingConditionImage && (
                        <TableRow>
                          <TableCell scope="row">
                            <Typography
                              variant="body2"
                              component="p"
                              fontFamily={theme.typography.fontFamily}
                              color={
                                theme.palette.type === 'light'
                                  ? theme.palette.text.primary
                                  : 'white'
                              }>
                              {t('hip_app.general.fixture')}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant="body2"
                              component="p"
                              fontFamily={theme.typography.fontFamily}
                              color={
                                theme.palette.type === 'light'
                                  ? theme.palette.text.primary
                                  : 'white'
                              }>
                              {props.ai === 'Shelfout Detection'
                                ? loadingConditionImage &&
                                parseFloat(
                                  props.images.metadata[props.aisle].find(
                                    detection =>
                                      detection.detection_id ===
                                      props.selectedLabel,
                                  ).fixture,
                                )
                                : 'NaN'}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              variant="body2"
                              component="p"
                              fontFamily={theme.typography.fontFamily}
                              color={
                                theme.palette.type === 'light'
                                  ? theme.palette.text.primary
                                  : 'white'
                              }>
                              NaN
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            {loadingConditionImage && labelMetadata ? (
              <StyledTextField
                id="standard-multiline-static"
                label="Item metadata"
                multiline
                rows={15}
                fullWidth
                sx={{ marginTop: '1rem' }}
                value={JSON.stringify(labelMetadata, null, 10)}
              />
            ) : null}
            {loadingConditionImage &&
              props.aisle &&
              props.images.metadata &&
              props.images.metadata[props.aisle] &&
              props.images.metadata[props.aisle].find(
                detection => detection.detection_id === props.selectedLabel,
              ) ? (
              <StyledTextField
                id="standard-multiline-static"
                label="Item metadata"
                multiline
                rows={15}
                fullWidth
                sx={{ marginTop: '1rem' }}
                value={JSON.stringify(
                  props.images.metadata[props.aisle].find(
                    detection => detection.detection_id === props.selectedLabel,
                  ),
                  null,
                  10,
                )}
              />
            ) : null}
          </Grid>
          {(props.isLoadingImages || props.isLoadingImageUrl) && (
            <LinearProgress
              sx={{
                width: '99.5%',
                backgroundColor: theme.palette.background.default,
              }}
            />
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default DetectionModal;
