import { Buffer } from 'buffer';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Switch,
  Tab,
  Typography,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import Input from '../tools/Input';
import Selector from '../tools/Selector';

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.type === 'light' ? 'white' : 'primary',
  '& .MuiSlider-thumb': {
    color: theme.palette.type === 'light' ? 'white' : 'primary',
  },
  '& .MuiSlider-rail': {
    color: theme.palette.type === 'light' ? 'white' : 'primary',
  },
  '& .MuiSlider-track': {
    color: theme.palette.type === 'light' ? 'white' : 'primary',
  },
}));

const StyledMenu = styled(props => <Menu {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor:
      theme.palette.type === 'light' ? '#1a242d' : theme.palette.paper.main,
    borderRadius: 3,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color: theme.palette.mode === 'light' ? 'white' : 'black',
  },
}));

export default function ToolMenu(props) {
  const { REACT_APP_USE_BIFROST } = process.env;
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    formState,
    setFormState,
    format,
    setFormat,
    showDetection,
    setShowDetection,
    sideView,
    setSideView,
    currencyFormat,
    setCurrencyFormat,
    selectedLabel,
    opacity,
    setOpacity,
    page,
    setPage,
    openMenu,
    setOpenMenu,
    setSelectedLabel,
    stage,
    handleStageChange,
    tabValue,
    handleTabChange,
    canShowPositionPrediction,
    setCanShowPositionPrediction,
  } = props;
  const { inputBackground = 'white', Inputcolor } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredChains, setFilteredChains] = useState(null);
  const openConfig = Boolean(anchorEl);
  const lightBackground = inputBackground
    ? inputBackground
    : Object.values(theme.palette[inputBackground])[0];

  const [isPositionSwitchLoading, setIsPositionSwitchLoading] = useState(false);

  const listAI = [
    {
      name: 'Label Detection',
      types: ['Overhead', 'Shelf'],
      label: t('hip_app.general.label_detection'),
    },
    {
      name: 'Label Reading',
      types: ['Overhead', 'Shelf'],
      label: t('hip_app.general.label_reading'),
    },
    {
      name: 'Product Detection',
      types: ['Shelf'],
      label: t('hip_app.general.product_detection'),
    },
    {
      name: 'Overhead Inventory Box Detection',
      types: ['Overhead'],
      label: t('hip_app.general.overhead_inventory_box_detection'),
    },
    {
      name: 'Overhead Inventory Box Reading',
      types: ['Overhead'],
      label: t('hip_app.general.overhead_inventory_box_reading'),
    },
    {
      name: 'Shelfout Detection',
      types: ['Wall'],
      label: t('hip_app.general.shelfout_detection'),
    },
    {
      name: 'Shelf Detection',
      types: ['Wall'],
      label: t('hip_app.general.shelf_detection'),
    },
    {
      name: 'Bay Detection',
      types: ['Wall'],
      label: t('hip_app.general.bay_detection'),
    },
    {
      name: 'Promotional Signage Detection',
      types: ['Shelf'],
      label: t('hip_app.general.promotional_detection'),
    },
    {
      name: 'Promotional Signage Reading',
      types: ['Shelf'],
      label: t('hip_app.general.promotional_reading'),
    },
  ];

  const listType = [
    { name: 'Overhead', label: t('hip_app.general.overhead') },
    { name: 'Shelf', label: t('hip_app.general.shelf') },
    { name: 'Wall', label: t('hip_app.general.wall') },
  ];

  const listLimit = [
    { name: '100' },
    { name: '200' },
    { name: '500' },
    { name: '1000' },
    { name: '2000' },
    { name: '5000' },
    { name: '10000' },
  ];

  const listCollector = [
    { name: 'all', label: t('hip_app.general.all') },
    { name: 'collector1', label: t('hip_app.general.collector1') },
    { name: 'collector2', label: t('hip_app.general.collector2') },
    { name: 'collector3', label: t('hip_app.general.collector3') },
  ];

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseConfig = () => {
    setAnchorEl(null);
  };

  const handleShare = () => {
    const currentState = {
      ...formState,
      image_url: props.images.image[formState.index].image_url,
      ...currencyFormat,
      selectedLabel: selectedLabel,
      format: format,
      showDetection: showDetection,
      opacity: opacity,
      filteredChains: filteredChains,
      sideView: sideView,
      page: page,
    };
    const state = JSON.stringify(currentState);
    const encodedState = Buffer.from(state, 'utf8').toString('base64');
    const link = `${window.location.origin}/share?state=${encodedState}&view=visualizer`;
    navigator.clipboard.writeText(link);
    props.setSnackAlert({
      open: true,
      message: t('hip_app.general.share_link_alert'),
      severity: 'success',
    });
  };

  const handleShowAll = value => {
    setFormState({
      ...formState,
      showAll: value,
      index: 0,
    });
    setPage(1);
    props.cleanImages();
    props.cleanImage();
    props.getImages({
      store: formState.store,
      session: formState.session,
      object_type: formState.type,
      ia_type: formState.ia,
      capture_id: formState.capture,
      detection_id: formState.detection,
      filter: value,
      rows_limit: formState.limit,
      aisles: handleGetAisle(),
    });
  };

  const handleGetAisle = (inputAisle = null) => {
    const targetAisleInfo = inputAisle ?? formState.aisle;
    return targetAisleInfo
      ? targetAisleInfo === 'All'
        ? []
        : [targetAisleInfo]
      : [];
  };

  const handleChange = (name, value, session) => {
    if (name === 'country') {
      if (formState.chain) {
        props.cleanStores();
        props.cleanImages();
        props.cleanImage();
      }
      setFormState({
        ...formState,
        store: null,
        aisle: 'All',
        session: null,
        ia: null,
        type: null,
        detection: '',
        capture: '',
        sku: '',
        country: value,
        index: 0,
        chain: null,
        collector: 'all',
      });
      setPage(1);
    } else if (name === 'chain') {
      if (formState.country) {
        props.cleanStores();
        props.cleanSessions();
        props.cleanImages();
        props.cleanImage();
        props.getStores({
          country_id: formState.country,
          chain_id: value,
        });
      }
      setFormState({
        ...formState,
        aisle: 'All',
        session: null,
        ia: null,
        type: null,
        detection: '',
        capture: '',
        sku: '',
        store: null,
        chain: value,
        index: 0,
        collector: 'all',
      });
      setPage(1);
    } else if (name === 'store') {
      props.cleanSessions();
      props.cleanImages();
      props.cleanImage();
      props.getSessions(value, REACT_APP_USE_BIFROST);
      setFormState({
        ...formState,
        store: value,
        aisle: 'All',
        session: null,
        detection: '',
        capture: '',
        sku: '',
        index: 0,
        collector: 'all',
      });
      setPage(1);
      const storeCurrency = props.stores.response.filter(
        store => store.code === value,
      )[0];
      setCurrencyFormat({
        currency_symbol: storeCurrency.currency_symbol,
        currency_thousands_sep: storeCurrency.currency_thousands_sep,
        currency_decimal_sep: storeCurrency.currency_decimal_sep,
        currency_decimals: storeCurrency.currency_decimals,
      });
    } else if (name === 'session') {
      props.getAisles(value, formState.store, REACT_APP_USE_BIFROST);
      setFormState({
        ...formState,
        session: value,
        auxSession: value,
        detection: '',
        capture: '',
        sku: '',
        aisle: 'All',
        index: 0,
        collector: 'all',
      });
      setPage(1);
      if (formState.type && formState.ia && formState.aisle) {
        props.cleanImages();
        props.cleanImage();
        props.getImages({
          store: formState.store,
          session: value,
          object_type: formState.type,
          ia_type: formState.ia,
          filter: formState.showAll,
          rows_limit: formState.limit,
          aisles: handleGetAisle(),
        });
      }
    } else if (name === 'aisle') {
      setFormState({
        ...formState,
        aisle: value,
        detection: '',
        capture: '',
        sku: '',
        index: 0,
      });
      setPage(1);
      if (formState.type && formState.ia) {
        props.cleanImages();
        props.cleanImage();
        props.getImages({
          store: formState.store,
          session: formState.session,
          aisles: handleGetAisle(value),
          object_type: formState.type,
          ia_type: formState.ia,
          filter: formState.showAll,
          rows_limit: formState.limit,
          collector: formState.collector,
        });
      }
    } else if (name === 'type') {
      props.cleanImages();
      setFormState({
        ...formState,
        type: value,
        detection: '',
        capture: '',
        sku: '',
        index: 0,
        ia: null,
      });
      setPage(1);
    } else if (name === 'ia') {
      props.cleanImages();
      props.cleanImage();
      setFormState({
        ...formState,
        ia: value,
        detection: '',
        index: 0,
        capture: '',
        sku: '',
      });
      setPage(1);
      props.getImages({
        store: formState.store,
        session: formState.session,
        object_type: formState.type,
        ia_type: value,
        filter: formState.showAll,
        rows_limit: formState.limit,
        collector: formState.collector,
        aisles: handleGetAisle(),
      });
    } else if (name === 'capture') {
      props.cleanImages();
      props.cleanImage();
      setFormState({
        ...formState,
        capture: value,
        index: 0,
        detection: '',
        sku: '',
      });
      setPage(1);
      props.getImages({
        store: formState.store,
        session: formState.session,
        object_type: formState.type,
        ia_type: formState.ia,
        capture_id: value,
        filter: false,
        rows_limit: formState.limit,
        collector: formState.collector,
        aisles: handleGetAisle(),
      });
    } else if (name === 'detection') {
      props.cleanImages();
      props.cleanImage();
      setFormState({
        ...formState,
        detection: value,
        index: 0,
        aisle: formState.aisle,
        capture: '',
        ia: formState.ia,
        auxSession: session ? session : formState.session,
      });
      setPage(1);
      props.getImages({
        store: formState.store,
        session: session ? session : formState.session,
        object_type: formState.type,
        ia_type: formState.ia,
        detection_id: value,
        filter: false,
        rows_limit: formState.limit,
        collector: formState.collector,
        aisles: handleGetAisle(),
      });
    } else if (name === 'limit') {
      props.cleanImages();
      props.cleanImage();
      setFormState({
        ...formState,
        limit: value,
        index: 0,
      });
      setPage(1);
      if (formState.ia) {
        props.getImages({
          store: formState.store,
          session: formState.session,
          object_type: formState.type,
          ia_type: formState.ia,
          capture_id: formState.capture,
          detection_id: formState.detection,
          filter: formState.showAll,
          rows_limit: value,
          aisles: handleGetAisle(),
          collector: formState.collector,
        });
      }
    } else if (name === 'sku') {
      props.cleanImages();
      props.cleanImage();
      props.clearDetections();

      if (value) {
        setFormState({
          ...formState,
          sku: value,
          index: 0,
          detection: null,
          capture: null,
        });
        setPage(1);
        props.getDetectionsByValue({
          store: formState.store,
          value,
          object_type: formState.type,
          ia_type: formState.ia,
          aisles: handleGetAisle(),
          rows_limit: formState.limit,
          session: formState.session,
        });
      } else {
        setFormState({
          ...formState,
          sku: value,
          index: 0,
          detection: '',
          capture: '',
        });
        setPage(1);
        props.getImages({
          store: formState.store,
          session: formState.session,
          object_type: formState.type,
          ia_type: formState.ia,
          filter: formState.showAll,
          rows_limit: formState.limit,
          aisles: handleGetAisle(),
          collector: formState.collector,
        });
      }
    } else if (name === 'collector') {
      props.cleanImages();
      props.cleanImage();
      setFormState({
        ...formState,
        index: 0,
        collector: value,
      });
      setPage(1);

      if (formState.ia) {
        props.getImages({
          store: formState.store,
          session: formState.session,
          object_type: formState.type,
          ia_type: formState.ia,
          capture_id: formState.capture,
          detection_id: formState.detection,
          filter: formState.showAll,
          rows_limit: formState.limit,
          aisles: handleGetAisle(),
          collector: value,
        });
      }
    }
  };

  const handlePositionPredictionChange = async () => {
    setIsPositionSwitchLoading(true);
    setCanShowPositionPrediction(!canShowPositionPrediction);
    setTimeout(() => {
      setIsPositionSwitchLoading(false);
    }, 500);
  };

  useEffect(() => {
    const { countries, chains } = props;

    if (!countries || !chains) {
      props.getCountries();
      props.getChains();
    }

    if (props.images && props.images.image && formState.index === 0 && props.images.image[formState.index]) {
      props.cleanImage();
      props.signImage({
        store: formState.store,
        blob_path: props.images.image[formState.index].image_url,
      });
    }

    if (props.sessions && props.sessions.sessions && formState.session === null && props.sessions.sessions[0] && props.sessions.sessions[0].includes(formState.store) && formState.country && formState.chain) {
      handleChange('session', props.sessions.sessions[0]);
    }

    if (props.detections && props.detections[0] && props.detections[0].detection_id && formState.detection === null) {
      handleChange(
        'detection',
        props.detections[0].detection_id,
        props.detections[0].session,
      );
    }

    if (props.recievedState && !formState.country && !formState.chain && !formState.store) {
      const state = props.recievedState;

      props.getStores({
        country_id: state.country,
        chain_id: state.chain,
      });
      props.getSessions(state.store, REACT_APP_USE_BIFROST);
      props.getAisles(state.session, state.store, REACT_APP_USE_BIFROST);

      if (state.sku) {
        props.getDetectionsByValue({
          store: state.store,
          value: state.sku,
          object_type: state.type,
          ia_type: state.ia,
          aisles: handleGetAisle(),
          rows_limit: state.limit,
        });
      }

      setFormState({
        country: state.country,
        chain: state.chain,
        store: state.store,
        aisle: state.aisle,
        session: state.session,
        ia: state.ia,
        detection: state.detection,
        capture: state.capture,
        type: state.type,
        index: state.index,
        showAll: state.showAll,
        limit: state.limit,
        sku: state.sku,
        auxSession: state.session,
        collector: state.collector,
      });

      props.getImages({
        store: state.store,
        session: state.auxSession,
        object_type: state.type,
        ia_type: state.ia,
        capture_id: state.capture,
        detection_id: state.detection,
        filter: state.showAll,
        rows_limit: state.limit,
        aisles: handleGetAisle(state.aisle),
      });

      props.signImage({
        store: state.store,
        blob_path: state.image_url,
      });

      setCurrencyFormat({
        currency_symbol: state.currency_symbol,
        currency_thousands_sep: state.currency_thousands_sep,
        currency_decimal_sep: state.currency_decimal_sep,
        currency_decimals: state.currency_decimals,
      });

      setFormat(state.format);
      setSelectedLabel(state.selectedLabel);
      setShowDetection(state.showDetection);
      setOpacity(state.opacity);
      setFilteredChains(state.filteredChains);
      setSideView(state.sideView);
      setPage(state.page);
    }
  }, [formState, props.images, props.sessions, props.detections]);

  useEffect(() => {
    var countries = props.countries;
    var chains = props.chains;

    if (countries && countries.response && chains && chains.response && formState.country) {
      var countryName = countries.response.filter(country => country.country_id === formState.country)[0].name;
      const filteredChains = chains.response.filter(chain => chain.country_name === countryName);
      setFilteredChains(filteredChains);
    }
  }, [formState.country]);

  useEffect(() => {
    if (props.aisles && props.aisles.aisles) {
      props.aisles.aisles.sort((a, b) => {
        return a.aisle.localeCompare(b.aisle, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
    }
  }, [props.aisles]);

  return (
    <Paper
      component={Grid}
      container
      p={2}
      sx={{
        backgroundColor:
          theme.palette.type === 'light'
            ? lightBackground
            : theme.palette.paper.main,
      }}>
      {/* Settings menu */}
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openConfig}
        onClose={handleCloseConfig}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              variant="fullWidth"
              textColor="inherit">
              <Tab label="Options" value="Options" />
              {[
                'Label Reading',
                'Overhead Inventory Box Reading',
                'Promotional Signage Reading',
              ].includes(formState.ia) ? (
                <Tab label="Stages" value="Stages" />
              ) : null}
              {formState.ia === 'Shelfout Detection' ? (
                <Tab label="Display" value="Display" />
              ) : null}
            </TabList>
          </Box>
          <TabPanel value="Options">
            {['Label Reading', 'Promotional Signage Reading'].includes(
              formState.ia,
            ) ? (
              <MenuItem onClick={handleCloseConfig}>
                <FormControlLabel
                  control={
                    <Switch
                      color={
                        theme.palette.type === 'light' ? 'secondary' : 'primary'
                      }
                      size="small"
                      checked={format}
                      onChange={e => setFormat(e.target.checked ? true : false)}
                    />
                  }
                  label={t('hip_app.general.format_currency')}
                  labelPlacement="end"
                />
              </MenuItem>
            ) : null}

            {formState.ia ? (
              <>
                <MenuItem onClick={handleCloseConfig}>
                  <FormControlLabel
                    control={
                      <Switch
                        color={
                          theme.palette.type === 'light'
                            ? 'secondary'
                            : 'primary'
                        }
                        size="small"
                        checked={showDetection}
                        onChange={e =>
                          setShowDetection(e.target.checked ? true : false)
                        }
                      />
                    }
                    label={t('hip_app.general.show_bounding_boxes')}
                    labelPlacement="end"
                  />
                </MenuItem>
                <MenuItem onClick={handleCloseConfig}>
                  <FormControlLabel
                    control={
                      <Switch
                        color={
                          theme.palette.type === 'light'
                            ? 'secondary'
                            : 'primary'
                        }
                        size="small"
                        checked={formState.showAll}
                        onChange={e => handleShowAll(e.target.checked)}
                      />
                    }
                    label={t('hip_app.general.show_all')}
                    labelPlacement="end"
                  />
                </MenuItem>
              </>
            ) : null}
            <MenuItem onClick={handleCloseConfig}>
              <FormControlLabel
                control={
                  <Switch
                    color={
                      theme.palette.type === 'light' ? 'secondary' : 'primary'
                    }
                    size="small"
                    checked={sideView}
                    onChange={e => setSideView(e.target.checked ? true : false)}
                  />
                }
                label={t('hip_app.general.side_by_side')}
                labelPlacement="end"
              />
            </MenuItem>
            <MenuItem>
              <Typography gutterBottom>
                {t('hip_app.general.opacity')}
              </Typography>
              <StyledSlider
                size="small"
                aria-label={t('hip_app.general.opacity')}
                value={opacity}
                valueLabelFormat={value => `${value * 100}%`}
                step={0.001}
                min={0}
                max={1}
                onChange={e => setOpacity(e.target.value)}
                sx={{ maxWidth: '70%', marginLeft: '1rem' }}
              />
            </MenuItem>
          </TabPanel>
          <TabPanel value="Stages">
            <FormControl>
              <RadioGroup
                aria-labelledby="stage-buttons"
                name="controlled-stage-buttons"
                value={stage}
                onChange={handleStageChange}>
                <FormControlLabel
                  value="text_recognition"
                  control={<Radio />}
                  label="First Stage Raw"
                />
                <FormControlLabel
                  value="text_recognition_postprocessed"
                  control={<Radio />}
                  label="First Stage Post Processed"
                />
                <FormControlLabel
                  value="results"
                  control={<Radio />}
                  label="First Stage Results"
                />
                <FormControlLabel
                  value="second_stage"
                  control={<Radio />}
                  label="Second Stage"
                />
              </RadioGroup>
            </FormControl>
          </TabPanel>
          <TabPanel value="Display">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={canShowPositionPrediction}
                    onChange={handlePositionPredictionChange}
                    name="position_prediction"
                    color={theme.palette.type === 'light' ? 'secondary' : 'primary'}
                    disabled={isPositionSwitchLoading}
                  />
                }
                label={t('hip_app.general.show_position_prediction')}
                sx={{
                  opacity: isPositionSwitchLoading ? 0.7 : 1,
                  pointerEvents: isPositionSwitchLoading ? 'none' : 'auto'
                }}
              />
            </FormGroup>
          </TabPanel>
        </TabContext>
        <Divider
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.23)',
          }}
        />
        <MenuItem
          onClick={() => {
            handleShare();
            handleCloseConfig();
          }}>
          <IconButton>
            <IconComponent
              className={'icon'}
              iconName={'share-social-outline'}
              style={{
                color: 'white',
                height: '2.3vh',
              }}
            />
          </IconButton>
          <Typography>{t('hip_app.general.share')}</Typography>
        </MenuItem>
      </StyledMenu>
      {/* Selectors */}
      <Grid container item xs={12} sm={11}>
        <Selector
          setSelectedElement={handleChange}
          name={'country'}
          disableClearable
          labelInput={t('hip_app.general.country')}
          options={props.countries ? props.countries.response : []}
          valueLabel={'country_id'}
          selectedElement={formState.country}
          optionLabel={'name'}
          disabled={props.isLoadingImages}
          required
          isLoading={props.isLoadingCountries}
          sx={{ flexGrow: 1 }}
        />
        <Selector
          setSelectedElement={handleChange}
          name={'chain'}
          labelInput={t('hip_app.general.chain')}
          options={filteredChains ? filteredChains : []}
          valueLabel={'chain_id'}
          selectedElement={formState.chain}
          optionLabel={'name'}
          disableClearable
          disabled={!filteredChains || props.isLoadingImages}
          required
          isLoading={props.isLoadingChains}
          sx={{ flexGrow: 1 }}
        />
        <Selector
          setSelectedElement={handleChange}
          name={'store'}
          labelInput={t('hip_app.general.store')}
          options={props.stores ? props.stores.response : []}
          valueLabel={'code'}
          selectedElement={formState.store}
          optionLabel={'name'}
          disabled={!props.stores || props.isLoadingImages}
          disableClearable
          required
          isLoading={props.isLoadingStores}
          sx={{ flexGrow: 1 }}
        />
        <Selector
          setSelectedElement={handleChange}
          name={'session'}
          labelInput={t('hip_app.general.session')}
          options={props.sessions ? props.sessions.sessions : []}
          valueLabel={'session'}
          selectedElement={formState.session}
          disabled={
            !props.sessions || !formState.store || props.isLoadingImages
          }
          defaultValue={formState.session ? true : false}
          required
          disableClearable
          isLoading={props.isLoadingSessions}
          sx={{ flexGrow: 1 }}
        />
        <Selector
          setSelectedElement={handleChange}
          name={'aisle'}
          labelInput={t('hip_app.general.aisle')}
          options={
            props.aisles
              ? [{ aisle: 'All', nav_finished: true }, ...props.aisles.aisles]
              : [{ aisle: 'All', nav_finished: true }]
          }
          valueLabel={'aisle'}
          selectedElement={formState.aisle}
          optionLabel={'aisle'}
          disabled={
            !props.aisles || !formState.session || props.isLoadingImages
          }
          isLoading={props.isLoadingAisles}
          defaultValue={formState.aisle ? true : false}
          required
          sx={{ flexGrow: 1 }}
        />
        <Selector
          setSelectedElement={handleChange}
          name={'type'}
          labelInput={t('hip_app.general.type')}
          options={listType}
          valueLabel={'name'}
          selectedElement={formState.type}
          optionLabel={'label'}
          required
          disableClearable
          disabled={
            !formState.limit ||
            !formState.session ||
            props.isLoadingImages ||
            !formState.session
          }
          sx={{ flexGrow: 1 }}
        />
        <Selector
          setSelectedElement={handleChange}
          name={'ia'}
          labelInput={t('hip_app.general.ai')}
          options={listAI.filter(item => item.types.includes(formState.type))}
          valueLabel={'name'}
          selectedElement={formState.ia}
          optionLabel={'label'}
          disableClearable
          disabled={
            !formState.type || props.isLoadingImages || !formState.session
          }
          required
          sx={{ flexGrow: 1 }}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={1}
        justifyContent="flex-end"
        alignContent="center">
        {/* Collapse button */}
        <IconButton onClick={() => setOpenMenu(!openMenu)}>
          <IconComponent
            iconName={openMenu ? 'chevron-up-outline' : 'chevron-down-outline'}
            style={{
              color:
                theme.palette.type === 'dark'
                  ? 'white'
                  : theme.palette.primary.main,
              height: '2.3vh',
            }}
          />
        </IconButton>
        {/* Settings button */}
        <IconButton onClick={handleClick}>
          <IconComponent
            iconName={'settings-outline'}
            style={{
              color:
                theme.palette.type === 'dark'
                  ? 'white'
                  : theme.palette.primary.main,
              height: '2.3vh',
            }}
          />
        </IconButton>
      </Grid>
      {/* Collapsable selectors and inputs */}
      <Collapse in={openMenu} style={{ width: '100%' }}>
        <Grid container item xs={12} sm={11}>
          <Selector
            setSelectedElement={handleChange}
            name={'limit'}
            labelInput={t('hip_app.general.image_limit')}
            options={listLimit}
            valueLabel={'name'}
            selectedElement={formState.limit}
            optionLabel={'name'}
            required
            disableClearable
            disabled={!formState.session || props.isLoadingImages}
            sx={{ flexGrow: 1 }}
          />
          {formState.type === 'Shelf' &&
            [
              'Label Detection',
              'Label Reading',
              'Product Detection',
              'Promotional Signage Reading',
            ].includes(formState.ia) ? (
            <Selector
              name={'collector'}
              labelInput={t('hip_app.general.collector')}
              valueLabel={'name'}
              selectedElement={formState.collector}
              disableClearable
              setSelectedElement={handleChange}
              options={listCollector}
              optionLabel={'label'}
              disabled={props.isLoadingImages}
              sx={{ flexGrow: 1 }}
            />
          ) : null}
          {props.stores &&
            props.sessions &&
            formState.ia &&
            [
              'Label Reading',
              'Overhead Inventory Box Reading',
              'Promotional Signage Reading',
            ].includes(formState.ia) ? (
            <Input
              name={'sku'}
              labelInput={'SKU'}
              selectedElement={formState.sku}
              setSelectedElement={handleChange}
              disabled={props.isLoadingImages}
              sx={{ flexGrow: 1 }}
            />
          ) : null}
          {props.stores &&
            props.sessions &&
            formState.ia &&
            formState.ia !== 'Shelfout Detection' ? (
            !formState.sku ? (
              <Input
                name={
                  [
                    'Label Reading',
                    'Overhead Inventory Box Reading',
                    'Promotional Signage Reading',
                  ].includes(formState.ia)
                    ? 'detection'
                    : 'capture'
                }
                labelInput={
                  [
                    'Label Reading',
                    'Overhead Inventory Box Reading',
                    'Promotional Signage Reading',
                  ].includes(formState.ia)
                    ? 'Detection ID'
                    : 'Capture ID'
                }
                selectedElement={
                  [
                    'Label Reading',
                    'Overhead Inventory Box Reading',
                    'Promotional Signage Reading',
                  ].includes(formState.ia)
                    ? formState.detection
                    : formState.capture
                }
                setSelectedElement={handleChange}
                disabled={
                  props.isLoadingImages ||
                  !formState.type ||
                  !formState.ia ||
                  formState.type === 'Wall'
                }
                sx={{ flexGrow: 1 }}
              />
            ) : (
              <Selector
                name={'detection'}
                labelInput={`Detection ID ${props.detections ? '(' + props.detections.length + ')' : ''
                  }`}
                valueLabel={'detection_id'}
                secondaryValueLabel="session"
                selectedElement={formState.detection}
                disableClearable={true}
                setSelectedElement={handleChange}
                options={props.detections ? props.detections : []}
                optionLabel="detection_id"
                groupLabel="session"
                isLoading={props.isLoadingDetections}
                disabled={props.isLoadingImages}
                sx={{ flexGrow: 1 }}
              />
            )
          ) : null}
        </Grid>
      </Collapse>
    </Paper>
  );
}
