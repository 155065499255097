import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Grid,
  Pagination,
  PaginationItem,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import Canvas from '../../redux/containers/visualizer/Canvas';
import DetectionModal from '../../redux/containers/visualizer/DetectionModal';
import TopMenu from '../../redux/containers/visualizer/TopMenu';
import { FIXTURES_DISPLAY } from '../tools/Canvas/utils';

export default function Visualizer(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { inputBackground = 'white', Inputcolor } = props;
  const lightBackground = inputBackground
    ? inputBackground
    : Object.values(theme.palette[inputBackground])[0];
  const styles = {
    paper: {
      width: '100%',
      height: '100%',
      padding: 2,
      textAlign: 'center',
      background:
        theme.palette.type === 'light'
          ? lightBackground
          : theme.palette.paper.main,
      color: Inputcolor
        ? Inputcolor
        : theme.palette.type === 'light'
          ? theme.palette.primary.main + 'D9'
          : 'rgba(255, 255, 255, 0.87)',
    },
  };

  const [currencyFormat, setCurrencyFormat] = useState({
    currency_symbol: null,
    currency_thousands_sep: null,
    currency_decimal_sep: null,
    currency_decimals: null,
  });
  const [canShowSecondStage, setCanShowSecondStage] = useState(false);
  const [stage, setStage] = useState('results');
  const [tabValue, setTabValue] = useState('Options');
  const [canShowPositionPrediction, setCanShowPositionPrediction] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [format, setFormat] = useState(true);
  const [showDetection, setShowDetection] = useState(true);
  const [opacity, setOpacity] = useState(0.5);
  const [sideView, setSideView] = useState(true);
  const [page, setPage] = useState(1);
  const [preMetadata, setPreMetadata] = useState(null);

  const [formState, setFormState] = useState({
    country: null,
    chain: null,
    store: null,
    aisle: null,
    session: null,
    ia: null,
    detection: '',
    capture: '',
    sku: '',
    type: null,
    index: 0,
    showAll: true,
    limit: '100',
    auxSession: null,
    collector: 'all',
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleStageChange = (event) => {
    setStage(event.target.value);
  };

  const handleIndexChange = (event, value) => {
    if (props.images && props.images.image) {
      props.cleanImage();
      props.signImage({
        store: formState.store,
        blob_path: props.images.image[value - 1].image_url,
      });
    }
    setPage(value);
    setFormState({
      ...formState,
      index: value - 1,
    });
  };

  const handleClose = () => {
    setOpen(false);
    props.cleanSecondaryImages();
    props.cleanSecondaryImage();
  };

  const handleOpen = (target_id, type, preMetadata) => {
    setPreMetadata(preMetadata);
    setOpen(true);
    props.cleanSecondaryImages();
    props.cleanSecondaryImage();
    let ia = '';
    if (type === 'capture') {
      ia = ['Label Reading', 'Promotional Signage'].includes(formState.ia)
        ? 'Label Detection'
        : formState.ia === 'Product Detection'
          ? 'Product Detection'
          : 'Overhead Inventory Box Detection';
      props.getSecondaryImages({
        store: formState.store,
        session: formState.session,
        object_type: formState.type,
        ia_type: ia,
        capture_id: target_id,
        filter: false,
        rows_limit: formState.limit,
        aisles: [handleGetAisleName()],
      });
    } else if (type === 'detection') {
      ia = ['Label Detection', 'Label Reading', 'Promotional Signage'].includes(
        formState.ia,
      )
        ? 'Label Reading'
        : formState.ia === 'Product Detection'
          ? 'Product Detection Reading'
          : formState.ia === 'Shelfout Detection'
            ? 'Shelfout Detection'
            : formState.ia === 'Promotional Signage Detection' ? 'Promotional Signage Reading'
              : 'Overhead Inventory Box Reading';
      props.getSecondaryImages({
        store: formState.store,
        session: formState.session,
        object_type: formState.type,
        ia_type: ia,
        detection_id: target_id,
        filter: false,
        rows_limit: formState.limit,
        aisles: [handleGetAisleName()],
      });
    } else if (type === 'product_detection_id') {
      props.getSecondaryImages({
        store: formState.store,
        session: formState.session,
        object_type: formState.type,
        ia_type: 'Product Detection Reading',
        capture_id: target_id.capture_id,
        product_detection_id: target_id.product_detection_id,
        filter: false,
        rows_limit: formState.limit,
        aisles: [handleGetAisleName()],
      });
    }
  };

  const handleGetAisleName = () => {
    const imageInfo = props?.images?.image?.[formState?.index];
    const imageAisleName = imageInfo?.aisle;
    const formAisleName = formState?.aisle;
    const baseAisleName = props.images.metadata[imageInfo.id]?.[0]?.aisle;

    return imageAisleName || baseAisleName || formAisleName || '-';
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Grid container spacing={2} sx={{ position: 'absolute' }}>
        <DetectionModal
          open={open}
          onClose={handleClose}
          selectedLabel={selectedLabel}
          store={formState.store}
          currencyFormat={currencyFormat}
          formatCurrency={format}
          aisle={
            formState.ia === 'Shelfout Detection' &&
              props.images &&
              props.images.image &&
              props.images.image[formState.index]
              ? props.images.image[formState.index].id
              : null
          }
          ai={formState.ia}
          preMetadata={preMetadata}
        />
        <Grid item xs={12}>
          <TopMenu
            formState={formState}
            setFormState={setFormState}
            format={format}
            setFormat={setFormat}
            showDetection={showDetection}
            setShowDetection={setShowDetection}
            sideView={sideView}
            setSideView={setSideView}
            currencyFormat={currencyFormat}
            setCurrencyFormat={setCurrencyFormat}
            selectedLabel={selectedLabel}
            opacity={opacity}
            setOpacity={setOpacity}
            page={page}
            setPage={setPage}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            setSelectedLabel={setSelectedLabel}
            canShowSecondStage={canShowSecondStage}
            setCanShowSecondStage={setCanShowSecondStage}
            stage={stage}
            setStage={setStage}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            handleStageChange={handleStageChange}
            canShowPositionPrediction={canShowPositionPrediction}
            setCanShowPositionPrediction={setCanShowPositionPrediction}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={['Label Reading', 'Overhead Inventory Box Reading', 'Promotional Signage Reading',].includes(formState.ia) && sideView ? 6 : 12}>
                <div id="wallCanvasContainer" className={'wallCanvasContainer'}>
                  {props.images &&
                    props.images.image &&
                    props.images.metadata &&
                    props.images.image[formState.index] &&
                    props.images.image[formState.index].id &&
                    props.image_url ? (
                    <Canvas
                      ai={formState.ia}
                      imageType={formState.type}
                      metadata={
                        showDetection
                          ? props.images.metadata[
                          props.images.image[formState.index].id
                          ]
                          : []
                      }
                      image_url={props.image_url}
                      currencyFormat={currencyFormat}
                      canShowSecondStage={canShowSecondStage}
                      canShowPositionPrediction={canShowPositionPrediction}
                      stage={stage}
                      tabValue={tabValue}
                      formatCurrency={format}
                      handleOpen={handleOpen}
                      setSelectedLabel={setSelectedLabel}
                      id={props.images.image[formState.index].id}
                      defaultZoom={
                        openMenu
                          ? [
                            'Label Reading',
                            'Overhead Inventory Box Reading',
                            'Promotional Signage Reading',
                          ].includes(formState.ia)
                            ? 50
                            : 50
                          : 60
                      }
                      splitView={
                        [
                          'Label Reading',
                          'Overhead Inventory Box Reading',
                          'Promotional Signage Reading',
                        ].includes(formState.ia) && sideView
                      }
                      factor={
                        [
                          'Label Reading',
                          'Overhead Inventory Box Reading',
                          'Promotional Signage Reading',
                        ].includes(formState.ia)
                          ? 2
                          : 1
                      }
                    />
                  ) : (
                    <Canvas
                      image={''}
                      ai={formState.ia}
                      imageType={formState.type}
                      metadata={[]}
                      defaultZoom={
                        openMenu
                          ? [
                            'Label Reading',
                            'Overhead Inventory Box Reading',
                            'Promotional Signage Reading',
                          ].includes(formState.ia)
                            ? 50
                            : 50
                          : 60
                      }
                      splitView={
                        [
                          'Label Reading',
                          'Overhead Inventory Box Reading',
                          'Promotional Signage Reading',
                        ].includes(formState.ia) && sideView
                      }
                      canShowSecondStage={canShowSecondStage}
                      canShowPositionPrediction={canShowPositionPrediction}
                      stage={stage}
                      tabValue={tabValue}
                    />
                  )}
                </div>
              </Grid>
              {[
                'Label Reading',
                'Overhead Inventory Box Reading',
                'Promotional Signage Reading',
              ].includes(formState.ia) &&
                sideView && (
                  <Grid item xs={6}>
                    <div
                      id="wallCanvasContainer"
                      className={'wallCanvasContainer'}>
                      {props.images &&
                        props.images.image &&
                        props.images.metadata &&
                        props.images.image[formState.index] &&
                        props.images.image[formState.index].id &&
                        props.image_url ? (
                        <Canvas
                          ai={formState.ia}
                          imageType={formState.type}
                          metadata={
                            props.images.metadata[
                            props.images.image[formState.index].id
                            ]
                          }
                          image_url={props.image_url}
                          currencyFormat={currencyFormat}
                          formatCurrency={format}
                          handleOpen={handleOpen}
                          setSelectedLabel={setSelectedLabel}
                          id={props.images.image[formState.index].id}
                          imageCanvas={'ReadingCanvas'}
                          canvasContainer={'ReadingCanvasContainer'}
                          readingCanvas={true}
                          splitView={true}
                          factor={2}
                          opacity={opacity}
                          chain={formState.chain}
                          defaultZoom={
                            openMenu
                              ? [
                                'Label Reading',
                                'Overhead Inventory Box Reading',
                                'Promotional Signage Reading',
                              ].includes(formState.ia)
                                ? 50
                                : 50
                              : 60
                          }
                          canShowSecondStage={canShowSecondStage}
                          canShowPositionPrediction={canShowPositionPrediction}
                          stage={stage}
                          tabValue={tabValue}
                        />
                      ) : (
                        <Canvas
                          image={''}
                          ai={formState.ia}
                          imageType={formState.type}
                          metadata={[]}
                          readingCanvas={true}
                          splitView={true}
                          imageCanvas={'ReadingCanvas'}
                          canvasContainer={'ReadingCanvasContainer'}
                          defaultZoom={
                            openMenu
                              ? [
                                'Label Reading',
                                'Overhead Inventory Box Reading',
                                'Promotional Signage Reading',
                              ].includes(formState.ia)
                                ? 50
                                : 50
                              : 60
                          }
                          canShowSecondStage={canShowSecondStage}
                          canShowPositionPrediction={canShowPositionPrediction}
                          stage={stage}
                          tabValue={tabValue}
                        />
                      )}
                    </div>
                  </Grid>
                )}
            </Grid>
            <Grid container alignItems='center' sx={{ mt: 1 }}>
              <Grid item xs={3}>
                <Typography variant="body2" align="left" sx={{ marginTop: 1 }}>
                  {props.images &&
                    props.images.image &&
                    props.images.image[formState.index] &&
                    props.images.image[formState.index].id
                    ? [
                      'Label Reading',
                      'Overhead Inventory Box Reading',
                      'Promotional Signage Reading',
                    ].includes(formState.ia)
                      ? `Detection ID: ${props.images.image[formState.index].id
                      }`
                      : formState.ia !== 'Shelfout Detection'
                        ? `Capture ID: ${props.images.image[formState.index].id}`
                        : ''
                    : ''}
                </Typography>
                {props?.images?.metadata &&
                  props?.images?.image?.[formState.index]?.id && (
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{ marginTop: 1 }}>
                      Aisle: {handleGetAisleName()}
                    </Typography>
                  )}
              </Grid>
              <Grid container item xs={6} justifyContent='center'>
                <Pagination
                  size='small'
                  hidden={!props.images || !props.images.image || props.images.image.length === 0}
                  count={props.images && props.images.image ? props.images.image.length : 0}
                  page={page}
                  siblingCount={useMediaQuery(theme.breakpoints.up('lg')) ? 4 : 0}
                  color="primary"
                  onChange={handleIndexChange}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      color: theme.palette.grey[500],
                    },
                    '& .Mui-selected': {
                      color: 'white',
                    },
                  }}
                  renderItem={item => (
                    <Tooltip
                      title={
                        props.images &&
                          props.images.image &&
                          props.images.image[item.page - 1]
                          ? props.images.image[item.page - 1].id
                          : ''
                      }>
                      <PaginationItem
                        {...item}
                        component="button"
                        color="primary"
                      />
                    </Tooltip>
                  )}
                />
                <Typography variant="body2" align="center">
                  {!formState.ia ? t('hip_app.general.no_filter') : ''}
                </Typography>
                <Typography variant="caption" align="center" sx={{ pt: 2 }}>
                  {props?.images?.images_without_info
                    ? `Images without data: ${props.images.images_without_info}`
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" align="right" sx={{ marginTop: 1 }}>
                  {props.images?.image?.[formState.index]?.id &&
                    formState.type === 'Shelf' &&
                    [
                      'Label Detection',
                      'Label Reading',
                      'Product Detection',
                      'Promotional Signage Reading',
                      'Promotional Signage Detection',
                    ].includes(formState.ia)
                    ? `${t('hip_app.general.collector')}: ${props.images.image[formState.index].source
                    }`
                    : ''}
                </Typography>
                {/* Fixture type legend */}
                {props.images?.image?.[formState.index]?.id && formState.ia === 'Shelfout Detection' &&
                  <Grid container spacing={2} justifyContent='flex-start'>
                    <Grid item>
                      <Grid container spacing={1} alignItems='center'>
                        <Box sx={{ width: '0.8em', height: '0.8em', borderRadius: 1, bgcolor: theme.palette.secondary.main, mr: 1 }} />
                        <Typography variant='caption'>{t('hip_app.general.shelfout', 'Shelfout')}</Typography>
                      </Grid>
                    </Grid>
                    {Object.entries(FIXTURES_DISPLAY).map(([_, value]) => (
                      <Grid item>
                        <Grid container spacing={1} alignItems='center'>
                          <Box sx={{ width: '0.8em', height: '0.8em', borderRadius: 1, bgcolor: value.color, mr: 1 }} />
                          <Typography variant='caption'>{t(`hip_app.general.${value.display_name}`, value.display_name)}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
