import qs from 'querystring';

export default function HTTPService() {
  const HTTP_METHOD_GET = 'GET';
  const HTTP_METHOD_POST = 'POST';
  const HTTP_METHOD_DELETE = 'DELETE';
  const HTTP_HEADER_ACCEPT = 'Accept';
  const HTTP_HEADER_CONTENT_TYPE = 'Content-Type';
  const HTTP_CREDENTIALS = 'credentials';
  const HTTP_CREDENTIALS_TYPE = 'include';

  const MIME_TYPE_JSON = 'application/json;charset=utf-8';

  return {
    getAuthenticated,
    postAuthenticated,
    deleteAuthenticated,
  };

  function getAuthenticated({ url, query }) {
    return makeRequest({
      method: HTTP_METHOD_GET,
      url,
      query,
    });
  }

  function postAuthenticated({ url, body, accessToken }) {
    return makeRequest({
      method: HTTP_METHOD_POST,
      url,
      body,
    });
  }

  function deleteAuthenticated({ url, body, accessToken }) {
    return makeRequest({
      method: HTTP_METHOD_DELETE,
      url,
      body,
    });
  }

  async function makeRequest({
    method,
    url,
    query = {},
    body = {},
    headers = {},
  }) {
    const finalUrl =
      query && Object.keys(query).length
        ? `${url}?${qs.stringify(query)}`
        : url;

    const finalHeaders = Object.keys(headers).reduce((acc, headerKey) => {
      acc.append(headerKey, headers[headerKey]);
      return acc;
    }, new Headers());

    finalHeaders.set(HTTP_HEADER_ACCEPT, MIME_TYPE_JSON);

    if (method === HTTP_METHOD_POST) {
      finalHeaders.set(HTTP_HEADER_CONTENT_TYPE, MIME_TYPE_JSON);
    }

    const requestDescription =
      method === HTTP_METHOD_POST
        ? {
          method,
          headers: finalHeaders,
          body: JSON.stringify(body),
          [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
        }
        : {
          method,
          headers: finalHeaders,
          [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
        };

    const response = await fetch(finalUrl, requestDescription);

    let responseBody = {};
    try {
      responseBody = await response.json();
    } catch (error) {
      console.log('%c Error while parsing response to JSON: ', 'color:red', error)
    }

    if (response.ok) {
      return responseBody;
    } else {
      if (response.status === 401) {
        window.location.reload();
      }
      const responseText = await response.text();
      throw new Error(responseText);
    }
  }
}
