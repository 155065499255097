import {
  GET_IMAGES_FAILURE,
  GET_IMAGES_REQUEST,
  GET_IMAGES_RESET,
  GET_IMAGES_SUCCESS,
  GET_IMAGES_URL_FAILURE,
  GET_IMAGES_URL_REQUEST,
  GET_IMAGES_URL_RESET,
  GET_IMAGES_URL_SUCCESS,
  GET_SECONDARY_IMAGES_FAILURE,
  GET_SECONDARY_IMAGES_REQUEST,
  GET_SECONDARY_IMAGES_RESET,
  GET_SECONDARY_IMAGES_SUCCESS,
  GET_SECONDARY_IMAGES_URL_FAILURE,
  GET_SECONDARY_IMAGES_URL_REQUEST,
  GET_SECONDARY_IMAGES_URL_RESET,
  GET_SECONDARY_IMAGES_URL_SUCCESS,
} from './constants';
import { createReducer } from './utils';

const defaultState = {
  loading: false,
  loading_secondary: false,
  loading_url: false,
  images: null,
  errorMessage: null,
  image_url: null,
  secondary_images: null,
  secondary_image_url: null,
};

// Reducer

export const reducer = createReducer(defaultState, {
  // TODO: replace reducers with the ones in reducerHandlers.js when it's not custom
  [GET_IMAGES_REQUEST]: handleImagesRequest,
  [GET_IMAGES_SUCCESS]: handleImagesSuccess,
  [GET_IMAGES_FAILURE]: handleImagesFailure,
  [GET_IMAGES_RESET]: handleImageReset,
  [GET_SECONDARY_IMAGES_REQUEST]: handleSecondaryImagesRequest,
  [GET_SECONDARY_IMAGES_SUCCESS]: handleSecondaryImagesSuccess,
  [GET_SECONDARY_IMAGES_FAILURE]: handleSecondaryImagesFailure,
  [GET_SECONDARY_IMAGES_RESET]: handleSecondaryImageReset,
  [GET_IMAGES_URL_SUCCESS]: handleImagesUrlSuccess,
  [GET_IMAGES_URL_FAILURE]: handleImagesUrlFailure,
  [GET_IMAGES_URL_REQUEST]: handleImagesUrlRequest,
  [GET_IMAGES_URL_RESET]: handleImagesReset,
  [GET_SECONDARY_IMAGES_URL_SUCCESS]: handleSecondaryImagesUrlSuccess,
  [GET_SECONDARY_IMAGES_URL_FAILURE]: handleSecondaryImagesUrlFailure,
  [GET_SECONDARY_IMAGES_URL_REQUEST]: handleSecondaryImagesUrlRequest,
  [GET_SECONDARY_IMAGES_URL_RESET]: handleSecondaryImagesReset,
});

function handleImagesRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleImagesSuccess(state, { payload: { images } }) {
  return {
    ...state,
    images,
    loading: false,
    errorMessage: null,
  };
}

function handleImagesFailure(state, { payload: { error } }) {
  return {
    loading: false,
    images: [],
    errorMessage: error,
  };
}

function handleImagesUrlSuccess(state, { payload: { image_url } }) {
  return {
    ...state,
    image_url,
    loading_url: false,
    errorMessage: null,
  };
}

function handleImagesUrlFailure(state, { payload: { error } }) {
  return {
    loading_url: false,
    image_url: {},
    errorMessage: error,
  };
}

function handleImagesUrlRequest(state) {
  return {
    ...state,
    loading_url: true,
    errorMessage: null,
  };
}

function handleImageReset(state) {
  return {
    ...state,
    loading: false,
    images: null,
    errorMessage: null,
    image_url: null,
  };
}

function handleImagesReset(state) {
  return {
    ...state,
    loading: false,
    loading_url: false,
    image_url: null,
    errorMessage: null,
  };
}

function handleSecondaryImagesRequest(state) {
  return {
    ...state,
    loading_secondary: true,
    errorMessage: null,
  };
}

function handleSecondaryImagesSuccess(state, { payload: { images } }) {
  return {
    ...state,
    secondary_images: images,
    loading_secondary: false,
    errorMessage: null,
  };
}

function handleSecondaryImagesFailure(state, { payload: { error } }) {
  return {
    loading_secondary: false,
    secondary_images: [],
    errorMessage: error,
  };
}

function handleSecondaryImagesUrlSuccess(state, { payload: { image_url } }) {
  return {
    ...state,
    secondary_image_url: image_url,
    loading_secondary: false,
    errorMessage: null,
  };
}

function handleSecondaryImagesUrlFailure(state, { payload: { error } }) {
  return {
    loading_secondary: false,
    secondary_image_url: {},
    errorMessage: error,
  };
}

function handleSecondaryImagesUrlRequest(state) {
  return {
    ...state,
    loading_secondary: true,
    errorMessage: null,
  };
}

function handleSecondaryImageReset(state) {
  return {
    ...state,
    loading_secondary: false,
    secondary_images: null,
    errorMessage: null,
    secondary_image_url: null,
  };
}

function handleSecondaryImagesReset(state) {
  return {
    ...state,
    loading_secondary: false,
    secondary_image_url: null,
    errorMessage: null,
  };
}

// Actions

export function getImages(payload) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_IMAGES_REQUEST });
    try {
      const images = await dataSource.getImages(payload);
      dispatch({
        type: GET_IMAGES_SUCCESS,
        payload: {
          images,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_IMAGES_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}

export function signImage(payload) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_IMAGES_URL_REQUEST });
    try {
      const image_url = await dataSource.signImage(payload);
      dispatch({
        type: GET_IMAGES_URL_SUCCESS,
        payload: {
          image_url,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_IMAGES_URL_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}

export function cleanImages() {
  return async dispatch => {
    dispatch({
      type: GET_IMAGES_RESET,
    });
  };
}

export function cleanImage() {
  return async dispatch => {
    dispatch({
      type: GET_IMAGES_URL_RESET,
    });
  };
}

export function getSecondaryImages(payload) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SECONDARY_IMAGES_REQUEST });
    try {
      const images = await dataSource.getImages(payload);
      dispatch({
        type: GET_SECONDARY_IMAGES_SUCCESS,
        payload: {
          images,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_SECONDARY_IMAGES_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}

export function signSecondaryImage(payload) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SECONDARY_IMAGES_URL_REQUEST });
    try {
      const image_url = await dataSource.signImage(payload);
      dispatch({
        type: GET_SECONDARY_IMAGES_URL_SUCCESS,
        payload: {
          image_url,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_SECONDARY_IMAGES_URL_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
}

export function cleanSecondaryImages() {
  return async dispatch => {
    dispatch({
      type: GET_SECONDARY_IMAGES_RESET,
    });
  };
}

export function cleanSecondaryImage() {
  return async dispatch => {
    dispatch({
      type: GET_SECONDARY_IMAGES_URL_RESET,
    });
  };
}
